import React, { useEffect, useState } from "react";
import { View, Platform, SafeAreaView, StatusBar, Text } from "react-native";
import styles from "../components/style/styles";
import CoreText from "../components/core/CoreText";
import Header from "../components/core/Header";
import CoreFooter from "../components/core/CoreFooter";
import CoreLoader from "../components/core/CoreLoader";
import { myDetails } from "../actions";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import { useTranslation } from "react-i18next";

const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");
const MontserratLight = require("../assets/Font/Montserrat-Light.ttf");

const Profile = ({ navigation, route }) => {
  const { t } = useTranslation();
  const [isLoad, setIsLoad] = useState(true);
  const organisationId = route.params.organisationId;
  const [userData, setUserData] = useState({});

  useEffect(async () => {
    await myDetails(organisationId)
      .then((resp) => {
        setUserData(resp?.data[0]);
        setIsLoad(false);
      })
      .catch((error) => {
        setIsLoad(false);
      });
  }, []);

  const useFields = [
    { label: t("app.email", "E-post"), value: userData?.email },
    { label: t("app.user_name", "Namn"), value: userData?.name },
    { label: t("app.address", "Adress"), value: userData?.address },
    { label: t("app.phone", "Telefon"), value: userData?.phone },
  ];

  return isLoad ? (
    <CoreLoader
      style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
    />
  ) : (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <Header navigation={navigation} route={route} isHeader />
      <View style={[styles.Landingcontainer, { paddingHorizontal: 20 }]}>
        <CoreText
          textTitle={t("app.my_profile", "Min profil")}
          style={[styles.titleHeader, { fontSize: 26,fontWeight:700 }]}
          fontType={MontserratBold}
        />
        <View style={{ textAlign: "center" }}>
          <FontAwesome
            name="user-circle-o"
            size={100}
            color="#DDDDDD"
            style={{ paddingTop: 10 }}
          />
          <CoreText
            style={{
              fontSize: 20,
              paddingTop: 5,
            }}
            textTitle={userData?.name}
            fontType={MontserratLight}
          />
        </View>
        <View
          style={{
            backgroundColor: "#f2f2f2",
            marginTop: 25,
            padding: 20,
            borderRadius: 10,
          }}
        >
          {useFields.map(({ label, value }) => (
            <View
              key={label}
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
                paddingBottom: 10,
              }}
            >
              <Text style={{ fontSize: 16,fontWeight: 600 }}>{label} :</Text>
              <Text style={{ fontSize: 16 }}>{value || "--"} </Text>
            </View>
          ))}
        </View>
      </View>
      <CoreFooter />
    </SafeAreaView>
  );
};
export default Profile;
