import React, { useState, useEffect } from "react";
import { View, Platform } from "react-native";
import Colors from "../../constants/Colors";
import CoreText from "../core/CoreText";
import styles from "../../components/style/styles";
import { Col, Grid } from "react-native-easy-grid";
import CoreBanner from "../../components/core/CoreBanner";
import { useSelector } from "react-redux";
import { AntDesign } from "@expo/vector-icons";
import CoreLoader from "../core/CoreLoader";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ReadyToSaleStatus } from "../../sharedLib/sourcingItemStatus";
import { useTranslation } from 'react-i18next';

import { myDetails, getCustomerAllData } from "../../actions";

const MontserratBold = require("../../assets/Font/Montserrat-Bold.ttf");
const MontserratLight = require("../../assets/Font/Montserrat-Light.ttf");

let organisationId;
const CoreBagCollectionHeader = ({ isHeaderImage, navigation }) => {
  const { t } = useTranslation();
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const orgSetting = useSelector((state) => state.prodReducer.orgSetting);
  const [totalSourceItems, setTotalSourceItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true)
  const organisation = orgDetail[0]?.urlPath;
  const customers = useSelector((state) => state.prodReducer.customerDetail);

  const [userData, setUseData] = useState("");

  useEffect(async () => {
    organisationId = await AsyncStorage.getItem("organisation");
      try {
        setIsLoading(true)
        const resp = await getCustomerAllData(customers?.id, organisationId);
        resp?.data?.map((item) => {
          if (item.sale_status === 3) {
            setTotalSourceItems(item.count)
          }
          setIsLoading(false)
        })
      } catch (err) {
        setIsLoading(false)
        console.error(`Oeps, something is wrong ${err}`);
      }
  }, []);

  useEffect(async () => {
    await myDetails(organisation)
      .then((resp) => {
        setUseData(resp?.data[0]);
      })
      .catch((error) => { });
  }, []);

  return (
    <>
      {isHeaderImage && (
        <Grid>
          <Col size={6}>
            <CoreBanner imgUrl={orgDetail?.[0]?.reg_banner} isCoreImage />
          </Col>
          <Col
            size={6}
            style={{
              backgroundColor: orgDetail?.[0]?.primaryColor || Colors.BROWN,
            }}
          >
            <View style={{ paddingLeft: 10, paddingTop: 30 }}>
              <CoreText
                style={styles.collectionTitle}
                textTitle={t("app.hello", "Hej,")}
                fontType={MontserratLight}
              />
              <CoreText
                style={{
                  // fontWeight: 700,
                  fontSize: 18,
                  color: Colors.WHITE,
                }}
                textTitle={userData?.name}
                fontType={MontserratLight}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems:"center",
                paddingLeft: 10,
                paddingTop: 45,
              }}
            >
              <CoreText
                style={{
                  color: Colors.WHITE,
                  fontSize: 16,
                  fontWeight: 700,
                }}
                textTitle={t("app.my_profile", "Min profil")}
                fontType={MontserratLight}
              />
              <AntDesign
                name="arrowright"
                size={20}
                color="white"
                style={{ paddingLeft: 5 }}
                onPress={()=>{
                  navigation.navigate("Profile", {
                    organisationId: organisationId,
                  });
                }}
              />
            </View>
          </Col>
        </Grid>
      )}
      <View
        style={
          ({ backgroundColor: Colors.LIGHT_TEXT_GRAY },
            Platform?.OS === "web"
              ? {
                position: "sticky",
                left: 0,
                top: 0,
                right: 0,
                backgroundColor: "#F5F5F5",
                zIndex: 10,
              }
              : { backgroundColor: Colors.LIGHT_TEXT_GRAY })
        }
      >
        <View style={styles.soldItemSection}>
          <Grid style={styles.numberOfSoldData}>
            <Col size={6}>
              {isLoading ? (
                <CoreLoader
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  isHeader
                />
              ) : (
                <>
                  <CoreText
                    style={styles.numberOfSold}
                    textTitle={t("app.no_of_sod_item", "Antal sålda plagg")}
                    fontType={MontserratBold}
                  />
                  <CoreText
                    style={styles.numberOfSoldTotal}
                    textTitle={totalSourceItems}
                    fontType={MontserratBold}
                  />
                </>)}
            </Col>
            <Col size={6}>
              {!userData ? (
                <CoreLoader
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  isHeader
                />
              ) : (
                <>
                  <CoreText
                    style={styles.numberOfSold}
                    textTitle={t("app.balance", "Balans")}
                    fontType={MontserratBold}
                  />
                  <CoreText
                    style={styles.numberOfSoldTotal}
                    textTitle={`${userData?.balance?.toFixed(2)} ${orgSetting?.currency ? orgSetting?.currency : "$"}`}
                    fontType={MontserratBold}
                  />
                </>
              )}
            </Col>
          </Grid>
        </View>
      </View>
    </>
  );
};

export default CoreBagCollectionHeader;
