import React, { useState, useEffect } from "react";
import {
  View,
  Platform,
  SafeAreaView,
  StatusBar,
  KeyboardAvoidingView,
  Text,
  Image,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useSelector, useDispatch } from "react-redux";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import {
  createCustomerHashCode,
  getCustomerHashCode,
  firstLogin,
} from "../actions";
import { setAuthHeader } from "../axiosApi";
import { setCustomer } from "../components/redux/actions/prodActions";
import styles from "../components/style/styles.js";
import { TextInput, Checkbox } from "react-native-paper";
import { ScrollView } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";
import Button from "../components/Button";
import Header from "../components/core/Header";
import Colors from "../constants/Colors";
import CoreLoader from "../components/core/CoreLoader";
import { useTranslation } from 'react-i18next';

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratExtraLight = require("../assets/Font/Montserrat-Thin.ttf");

const Register = ({ navigation, route }) => {
  const { t } = useTranslation();
  const pickOrg = route.params.organisationId;
  const emailData = decodeURIComponent(route.params.user).split(' ').join('+');
  const hashCode = route.params.hashCode;
  const [isError, setIsError] = useState(false);
  const [isLoadCustomer, setIsLoadCustomer] = useState(false);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [checked, setChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [userHashCode, setUserHashCode] = useState("");
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [codeError, setCodeError] = useState(false);
  const [codeErrorMsg, setCodeErrorMsg] = useState("");
  const [email, setEmail] = useState(emailData);
  const [userErrorMsg, setUserErrorMsg] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passwordMsg, setPasswordMsg] = useState("");
  const dispatch = useDispatch();
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const termsItem = orgDetail?.[0]?.terms_conditions;

  // support '+' oprator
  const reg =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // not support '+' oprator
  // const reg =
  // /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^\+[0-9]{12})+$/;

  const poolData = useSelector((state) => state.prodReducer.userPool);
  const userPoolData = poolData;

  useEffect(async () => {
    AsyncStorage.setItem("organisation", pickOrg);
    await getCustomerHashCode(pickOrg, emailData, hashCode).then((resp) => {
      setUserHashCode(resp.data);
      setIsLoad(false);
    });
  }, []);

  const handleNext = () => {
    if (email === "") {
      setIsError(true);
      setEmailErrorMsg("Ange en epost address");
      setIsLoadCustomer(false);
    } else if (username === "") {
      setIsError(true);
      setUserErrorMsg("Vänligen ange fullständigt namn");
      setIsLoadCustomer(false);
    } else if (password === "") {
      setIsError(true);
      setPasswordMsg("Vänligen ange ett giltigt lösenord");
      setIsLoadCustomer(false);
    } else if (!reg.test(email.split(" ").join(""))) {
      setCodeError(true);
      setCodeErrorMsg("Ange en giltigt epost");
    } else {
      firstLogin(pickOrg, email, password, hashCode)
        .then((resp) => {
          if (resp?.data == true) {
            const customerData = {
              email: emailData,
              organisationId: pickOrg,
              hashCode: hashCode,
              name: username,
              id: userHashCode?.id,
            };
            createCustomerHashCode(customerData);
            setRegisterSuccess(true);
          } else {
            setRegisterSuccess(false);
          }
          const authenticationData = {
            Username: email,
            Password: password,
          };
          const authDetails = new AuthenticationDetails(authenticationData);
          const userData = {
            Username: email,
            Pool: userPoolData,
          };

          const congnitoUser = new CognitoUser(userData);

          congnitoUser.authenticateUser(authDetails, {
            onSuccess(result = CognitoUserSession) {
              if (result) {
                AsyncStorage.setItem("user", JSON.stringify({ ...result }));
                AsyncStorage.setItem("token", result?.idToken?.jwtToken);
                setAuthHeader(result?.idToken?.jwtToken);
                const getUserInfo = result?.idToken?.payload;
                const customerData = {
                  email: getUserInfo?.email,
                  phoneNumber: getUserInfo?.phone_number,
                  id: getUserInfo?.sub,
                  isLogin: true,
                };
                dispatch(setCustomer(customerData));
                AsyncStorage.setItem("customer", JSON.stringify(customerData));
              }
            },
            onFailure(err) {
              console.log("err: ", err);
            },
          });
        })
        .catch((e) => {
          setRegisterSuccess(false);
        });
    }
  };
  const handleSubmitData = () => {
    handleNext();
  };
  return isLoad ? (
    <CoreLoader
      style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
    />
  ) : (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        enabled
      >
        <Header navigation={navigation} route={route} isHeader />
        {!registerSuccess ? (
          <ScrollView>
            {hashCode && userHashCode?.hash_code === hashCode ? (
              <>
                {" "}
                <View style={[styles.Landingcontainer]}>
                  <View style={[styles.alignItems, styles.justifyContent]}>
                    <View style={{ paddingHorizontal: 20 }}>
                      <CoreText
                        textTitle={t("app.register_title", "Fullfölj registrering")}
                        style={[
                          styles.mainTitle,
                          {
                            paddingTop: 36,
                            fontSize: 24,
                            paddingBottom: 16,
                            textAlign: "center",
                          },
                        ]}
                        fontType={MontserratMedium}
                      />
                      <CoreText
                        textTitle={t("app.register_subtitle", "Fyll i dina uppgifter")}
                        style={[
                          styles.subTitle,
                          { textAlign: "center", fontSize: 14 },
                        ]}
                        fontType={MontserratExtraLight}
                      />
                    </View>
                  </View>
                </View>
                <View style={[styles.btnsection, { marginTop: 16 }]}>
                  <>
                    <View style={{ marginBottom: 10 }}>
                      <TextInput
                        label={
                          <CoreText
                            textTitle={t("app.name", "Ditt namn")}
                            style={[styles.input]}
                            fontType={MontserratRegular}
                            isInput
                          />
                        }
                        autoComplete="off"
                        autoCompleteType="off"
                        style={[styles.inputFiledstyle]}
                        value={username}
                        keyboardType="default"
                        placeholderTextColor="#878787"
                        underlineColor="transparent"
                        activeUnderlineColor="transparent"
                        onChangeText={(text) => {
                          setIsError(false);
                          setUserErrorMsg("");
                          setUserName(text);
                        }}
                        theme={{ colors: { text: Colors.BLACK } }}
                      />
                      {isError && (
                        <CoreText
                          textTitle={isError && userErrorMsg}
                          style={[isError && styles.error]}
                          fontType={MontserratRegular}
                        />
                      )}
                    </View>
                    <View style={{ marginBottom: 10 }}>
                      <TextInput
                        label={
                          <CoreText
                            textTitle={t("app.email", "Epost")}
                            style={[styles.input]}
                            fontType={MontserratRegular}
                            isInput
                          />
                        }
                        style={[styles.inputFiledstyle]}
                        value={email}
                        keyboardType="default"
                        placeholderTextColor="#878787"
                        underlineColor="transparent"
                        activeUnderlineColor="transparent"
                        onChangeText={(text) => {
                          setEmail(text);
                          setCodeError(false);
                          setEmailErrorMsg("");
                        }}
                        theme={{ colors: { text: Colors.BLACK } }}
                      />
                      {isError && (
                        <CoreText
                          textTitle={isError && emailErrorMsg}
                          style={[isError && styles.error]}
                          fontType={MontserratRegular}
                        />
                      )}
                      {codeError && (
                        <CoreText
                          textTitle={codeError && codeErrorMsg}
                          style={[codeError && styles.error]}
                          fontType={MontserratRegular}
                        />
                      )}
                    </View>

                    <View>
                      <TextInput
                        label={
                          <CoreText
                            textTitle={t("app.password", "Lösenord")}
                            style={[styles.input]}
                            fontType={MontserratRegular}
                            isInput
                          />
                        }
                        autoComplete="off"
                        autoCompleteType="off"
                        style={[styles.inputFiledstyle, { marginBottom: 10 }]}
                        value={password}
                        secureTextEntry={showPassword}
                        right={
                          <TextInput.Icon
                            name={showPassword ? "eye-off" : "eye"}
                            onPress={() => setShowPassword(!showPassword)}
                            theme={{ colors: { text: Colors.BLACK } }}
                          />
                        }
                        maxLength={50}
                        keyboardType="default"
                        placeholderTextColor="#878787"
                        underlineColor="transparent"
                        activeUnderlineColor="transparent"
                        onChangeText={(e) => {
                          setIsError(false);
                          setPassword(e);
                        }}
                        theme={{ colors: { text: Colors.BLACK } }}
                      />
                      {isError && (
                        <CoreText
                          textTitle={isError && passwordMsg}
                          style={[isError && styles.error]}
                          fontType={MontserratRegular}
                        />
                      )}
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <Checkbox
                        status={checked ? "checked" : "unchecked"}
                        onPress={() => {
                          setChecked(!checked);
                        }}
                        color="#EDEDED"
                        theme={{ colors: { text: Colors.BLACK } }}
                      />{" "}
                      <CoreText
                        textTitle={
                          <>
                            {t("app.account_privacy", "Jag accepterar Serculate's")} {""}
                            <Text
                              style={styles.createAcoontText}
                              onPress={() => {
                                window.open(
                                  "https://www.serculate.com/tc",
                                  "_blank"
                                );
                              }}
                            >
                              {t("app.terms", "användarvillkor")}
                            </Text>{" "}
                            och {""}
                            <Text
                              style={styles.createAcoontText}
                              onPress={() => {
                                window.open(
                                  "https://www.serculate.com/gdrp",
                                  "_blank"
                                );
                              }}
                            >
                              {t("app.privacy", "integritetspolicy")}
                            </Text>
                          </>
                        }
                        style={[
                          styles.subTitle,
                          { fontSize: 12, paddingTop: 6 },
                        ]}
                        fontType={MontserratRegular}
                      />
                    </View>
                    {termsItem && (
                      <View style={{ flexDirection: "row" }}>
                        <Checkbox
                          status={termsChecked ? "checked" : "unchecked"}
                          onPress={() => {
                            setTermsChecked(!termsChecked);
                          }}
                          color="#EDEDED"
                          theme={{ colors: { text: Colors.BLACK } }}
                        />
                        <CoreText
                          textTitle={
                            <>
                              {t("app.register_privacy", "Jag har tagit del av och accepterar villkoren")}
                              <Text
                                style={styles.createAcoontText}
                                onPress={() => {
                                  window.open(
                                    `/TermsCondition?organisationId=${pickOrg}`,
                                    "_blank"
                                  );
                                }}
                              >
                                {""} {pickOrg}
                              </Text>
                              's villkor
                            </>
                          }
                          style={[
                            styles.subTitle,
                            { fontSize: 12, paddingTop: 6 },
                          ]}
                          fontType={MontserratRegular}
                        />
                      </View>
                    )}
                  </>
                </View>
              </>
            ) : (
              <View>
                <CoreText
                  textTitle="Invalid Token Type"
                  style={[
                    styles.mainTitle,
                    {
                      paddingTop: 36,
                      fontSize: 24,
                      paddingBottom: 16,
                      textAlign: "center",
                    },
                  ]}
                  fontType={MontserratMedium}
                />
              </View>
            )}
          </ScrollView>
        ) : (
          <ScrollView>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <View style={styles.blockdata}>
                <View style={styles.successimageContainer}>
                  <Image
                    style={styles.imgbanner}
                    source={require("../assets/images/itemsuccess.png")}
                  />
                </View>
              </View>
              <CoreText
                textTitle={t("app.register_message", "Tack för du är registerad!")}
                style={styles.titleHeader}
                fontType={MontserratMedium}
              />
            </View>
          </ScrollView>
        )}
        {registerSuccess ? (
          <Button
            btnTitle={t("app.login_button", "Logga in")}
            onPress={() =>
              navigation.navigate("SoldClothBagCollection", {
                organisationId: pickOrg,
              })
            }
            style={[
              styles.footerButtonSection,
              {
                marginHorizontal: 20,
              },
            ]}
            lineHeight={30}
          />
        ) : (
          <Button
            style={[
              styles.footerButtonSection,
              {
                marginHorizontal: 20,
              },
            ]}
            isLoad={isLoadCustomer}
            btnTitle={t("app.continue", "Fortsätt")}
            isError={isError}
            disableButtondata={
              (termsItem && !termsChecked) || !checked || isError || !email
            }
            disabled={
              (termsItem && !termsChecked) || !checked || isError || !email
            }
            textstyle={[
              !isError ? styles.btntextstyle : styles.disableBtnStyle,
              { lineHeight: 36 },
            ]}
            onPress={() => handleSubmitData(email, password)}
          />
        )}
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
export default Register;
