import React, { useState, useEffect } from "react";
import { Col, Grid } from "react-native-easy-grid";
import * as Location from "expo-location";
import {
  View,
  Image,
  Platform,
  SafeAreaView,
  StatusBar,
  TouchableOpacity,
  Text,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useSelector, useDispatch } from "react-redux";
import {
  setProduct,
  setProductDetails,
} from "../components/redux/actions/prodActions";
import { Card, Searchbar } from "react-native-paper";
import { AntDesign, MaterialIcons, Feather } from "@expo/vector-icons";
import styles from "../components/style/styles";
import { ScrollView } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";
import Colors from "../constants/Colors";
import CoreLoader from "../components/core/CoreLoader";
import {
  findservicePointbyPostalCode,
  nearestServicePoint,
  getProductDetailsById1,
  getOrganisationById,
  generateLogisticInstructions,
  selfServiceFlowUpdate,
} from "../actions";
import { setAuthHeader } from "../axiosApi";
import Header from "../components/core/Header";
import MobileMap from "../components/googlemap/MobileMap";
import WebMap from "../components/googlemap/WebMap";
import CoreFooter from "../components/core/CoreFooter";
import { useTranslation } from 'react-i18next';

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");
const MontserratLight = require("../assets/Font/Montserrat-Light.ttf");

let clearData = null;

const DropOffItem = ({ navigation, route }) => {
  const { t } = useTranslation();
  // let cartImage = route.params.cartImage || '';
  const textValue = route.params.textValue || "";
  const prodDetails = useSelector((state) => state.prodReducer.productDetail);
  let getDataById = 0;
  const [service, setService] = useState("");
  const [message, setMessage] = useState("");
  const [cartImage, setCartImage] = useState(route.params.cartImage);
  const [bookingResponse, setBookingResponse] = useState([]);
  const [isLoad, setIsLoad] = useState(true);
  const [isItemLoad, setIsItemLoad] = useState(false);
  const [dropOffItemData, setDropOffItemData] = useState(false);
  const dispatch = useDispatch();
  const [storeMessage, setStoreMessage] = useState(false);
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const orgSetting = useSelector((state) => state.prodReducer.orgSetting);
  const [coordinate, setCoordinate] = useState({
    latitude: 59.340946,
    longitude: 18.08395,
  });

  useEffect(async () => {
    const _token = await AsyncStorage.getItem("token");
    setAuthHeader(_token);
    const { user, item } = route.params;
    getDataById = item;
    if (item) {
      setIsItemLoad(true);
      // Get product details
      getProductDetailsById1(item).then(({ data }) => {
        dispatch(setProductDetails(data));
        setCartImage(data?.photo?.url);
        getOrganisationById(data?.organisation_id)
          .then((resp) => {
            dispatch(setProduct(resp?.data));
            setIsItemLoad(false);
          })
          .catch((error) => { });
      });

      try {
        console.log("Generate shipment instrucstions");
        generateLogisticInstructions(item)
          .then((resp) => {
            setBookingResponse(resp.data[0].printout);
            setIsLoad(false);
          })
          .catch((e) => {
            setIsLoad(false);
          });
      } catch (e) {
        setIsLoad(false);
      }
    }
  }, []);

  // Load closest service point
  useEffect(() => {
    if (!prodDetails?.id) {
      return;
    }
    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        setMessage(
          "Skriv in postnummer ovanför eller tillåt oss att hämta din posistion för att ladda närmaste "
        );
        return;
      }
      let location = await Location.getCurrentPositionAsync({});
      setIsLoad(true);
      nearestServicePoint(location.coords).then((resp) => {
        setService(
          resp?.data?.data?.servicePointInformationResponse?.servicePoints[0]
        );
        setIsLoad(false);
      });
    })();
  }, [prodDetails?.id]);

  const handleText = (e) => {
    if (clearData) {
      clearTimeout(clearData);
      clearData = null;
    }
    setMessage("");
    if (e.length > 4) {
      clearData = setTimeout(async () => {
        setIsLoad(true);
        const resp = await findservicePointbyPostalCode(e);
        const { data, status } = resp;
        if (status == "200") {
          if (data?.error) {
            setMessage("Kontrollera postnummer");
            setService("");
          } else {
            setService(data?.servicePointInformationResponse?.servicePoints[0]);

            setIsLoad(false);
          }
        } else {
          setMessage("Något gick fel, kan inte hämta postombud i närheten");
        }
      }, 1000);
    }
  };

  const handleClick = async () => {
    handlePrint()
    const orgName = await AsyncStorage.getItem("organisation")
    const data = {
      ...prodDetails,
      sku: sku
    }
    await selfServiceFlowUpdate(data, orgName)
  }

  const handleDropByStore = () => {
    setStoreMessage(true);
  };

  const { deliveryAddress, name, openingHours } = service || {};
  const { countryCode, city, streetName, streetNumber, postalCode } =
    deliveryAddress || {};
  const { postalServices } = openingHours || {};

  return isItemLoad ? (
    <View style={{ height: 500 }}>
      <CoreLoader
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    </View>
  ) : (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <Header navigation={navigation} route={route} isHomePage isHeader />
      <ScrollView>
        <View style={styles.Landingcontainer}>
          <View style={[styles.alignItems, styles.justifyContent]}></View>
          <View
            style={{
              paddingHorizontal: 15,
              paddingVertical: 15,
            }}
          >
            <View
              style={[
                styles.contentHeaderBody,
                { paddingBottom: 20, maxWidth: "100%" },
              ]}
            >
              <CoreText
                textTitle={t("app.dropOfItem_title", "Dina plagg är redo att lämnas")}
                style={styles.manageTitle}
                fontType={MontserratMedium}
              />
            </View>

            <View
              style={[
                styles.productContainer,
                {
                  marginBottom: 0,
                  backgroundColor: "#F9F6F1",
                  borderRadius: 20,
                },
              ]}
            >
              <View
                style={[
                  styles.productBody,
                  { height: 150, paddingVertical: 10, paddingHorizontal: 15 },
                ]}
              >
                <TouchableOpacity
                  onPress={() => navigation.navigate("ItemSoid", { cartImage })}
                >
                  <Image
                    style={[styles.productImage]}
                    source={{ uri: cartImage || prodDetails?.photo?.[0]?.url }}
                  />
                </TouchableOpacity>
                <View>
                  <CoreText
                    textTitle={prodDetails?.brand || prodDetails?.[0]?.brand}
                    style={styles.headerTitle}
                    fontType={MontserratRegular}
                  />
                  <CoreText
                    dropOffItem
                    textValue={textValue}
                    FirstTitle={
                      textValue
                        ? ""
                        : prodDetails?.gender || prodDetails?.[0]?.gender
                    }
                    lastTitle={
                      prodDetails?.category || prodDetails?.[0]?.category
                    }
                    icon={
                      textValue ? (
                        ""
                      ) : (
                        <AntDesign
                          name="right"
                          size={9}
                          color="rgba(15, 15, 15, 0.4)"
                          onPress={() => navigation.goBack()}
                        />
                      )
                    }
                    style={[
                      styles.headerSubTitle,
                      { color: "rgba(0, 0, 0, 0.48)" },
                    ]}
                    fontType={MontserratLight}
                  />
                  <View
                    style={[
                      styles.consideredByStore,
                      styles.headerText,
                      {
                        backgroundColor: Colors.WHITE,
                        borderRadius: 8,
                      },
                    ]}
                  >
                    <CoreText
                      style={styles.storeText}
                      textTitle={`Försäljningspris i butik ${prodDetails?.price || 0
                        } ${orgSetting?.currency ? orgSetting?.currency : "$"}`}
                      fontType={MontserratBold}
                    />
                  </View>

                  <View
                    style={[
                      styles.consideredByStore,
                      styles.headerText,
                      {
                        backgroundColor: Colors.WHITE,
                        borderRadius: 8,
                      },
                    ]}
                  >
                    <CoreText
                      style={styles.storeText}
                      textTitle={`Beräknade komission vid försäljning är ${prodDetails?.price * (prodDetails?.commission / 100) ||
                        0
                        } ${orgSetting?.currency ? orgSetting?.currency : "$"}`}
                      fontType={MontserratBold}
                    />
                  </View>
                </View>
              </View>
            </View>
            {!dropOffItemData ? (
              <View>
                <View style={{ marginTop: 10 }}>
                  <Card style={styles.cardStyle}>
                    <Card.Title
                      subtitleNumberOfLines={2}
                      style={[
                        styles.cardTitleStyle,
                        { alignItems: "flex-start" },
                      ]}
                      leftStyle={[styles.leftStyle, { marginTop: -2 }]}
                      title={
                        <CoreText
                          textTitle={t("app.dropOfItem_desc_1", "Se till att kläderna är tvättade oparfymerade")}
                          style={[styles.subtitleStyle]}
                          fontType={MontserratRegular}
                        />
                      }
                      titleNumberOfLines={2}
                      left={() => (
                        <Feather name="check" size={24} color="black" />
                      )}
                    />
                    <Card.Title
                      subtitleNumberOfLines={2}
                      style={[
                        styles.cardTitleStyle,
                        { alignItems: "flex-start" },
                      ]}
                      leftStyle={[styles.leftStyle, { marginTop: -2 }]}
                      title={
                        <CoreText
                          textTitle={t("app.dropOfItem_desc_2", "Kläder som är trasiga eller fläckiga kommer inte att säljas")}
                          style={[styles.subtitleStyle]}
                          fontType={MontserratRegular}
                        />
                      }
                      titleNumberOfLines={2}
                      left={() => (
                        <Feather name="check" size={24} color="black" />
                      )}
                    />
                  </Card>

                  {/*<TouchableOpacity
                    style={{
                      backgroundColor:
                        orgDetail?.[0]?.primaryColor || Colors.BROWN,
                      padding: 12,
                      width: 130,
                      alignItems: "center",
                      borderRadius: 15,
                      marginTop: 10,
                    }}
                    onPress={() => setDropOffItemData(true)}
                  >
                    <CoreText
                      style={{
                        color: Colors.WHITE,
                        fontSize: 15,
                        fontWeight: 700,
                      }}
                      textTitle="Jag förstår"
                      fontType={MontserratMedium}
                    />
                    </TouchableOpacity> */}
                  <View>
                    <CoreText
                      style={[styles.subtitleStyle, { marginTop: 20, marginBottom: 10 }]}
                      textTitle="You can either drop the item off in our stores or send it to us with a pre-paid mail"
                      fontType={MontserratBold}
                    />
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        style={[
                          styles.dropoffSection,
                          {
                            backgroundColor:
                              orgDetail?.[0]?.primaryColor || Colors.BROWN,
                          },
                          !prodDetails?.centra_id && {
                            cursor: "no-drop",
                            opacity: 0.5,
                          },
                        ]}
                        onPress={() => handleDropByStore()}
                        disabled={!prodDetails?.centra_id}
                      >
                        <CoreText
                          style={[styles.dropoffItembutton]}
                          textTitle="Drop it by store"
                          fontType={MontserratMedium}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.dropoffSection,
                          {
                            backgroundColor:
                              orgDetail?.[0]?.primaryColor || Colors.BROWN,
                          },
                        ]}
                        onPress={() => setDropOffItemData(true)}
                      >
                        <CoreText
                          style={[styles.dropoffItembutton]}
                          textTitle="Drop it by mail"
                          fontType={MontserratMedium}
                        />
                      </TouchableOpacity>
                    </View>
                    {storeMessage && (
                      <View>
                        <CoreText
                          textTitle={
                            <>
                              Show this{" "}
                              <Text
                                style={{ fontSize: 16, color: Colors.BROWN }}
                              >
                                {prodDetails?.centra_id}
                              </Text>{" "}
                              in any of our shops and they will happily retrive
                              the item
                            </>
                          }
                          style={{
                            marginTop: 20,
                            color: Colors.GRAY,
                            fontSize: 14,
                            fontWeight: 600,
                          }}
                          fontType={MontserratRegular}
                        />
                      </View>
                    )}
                  </View>
                </View>
              </View>
            ) : (
              <View>
                <CoreText
                  textTitle={t("app.dropOfItem_subtitle", "Innan du kommer in:")}
                  style={[styles.manageTitle, { marginTop: 15 }]}
                  fontType={MontserratMedium}
                />
                <View style={{ marginTop: 16 }}>
                  <Card style={styles.cardStyle}>
                    <Card.Title
                      subtitleNumberOfLines={2}
                      style={[
                        styles.cardTitleStyle,
                        { alignItems: "flex-start" },
                      ]}
                      leftStyle={[styles.leftStyle, { marginTop: -2 }]}
                      title={
                        <CoreText
                          textTitle={t("app.dropOfItem_desc_3", "Använd en påse och vik ner kläderna, gå till närmaste postombud")}
                          style={[styles.subtitleStyle]}
                          fontType={MontserratRegular}
                        />
                      }
                      titleNumberOfLines={2}
                      left={() => (
                        <Feather name="check" size={24} color="black" />
                      )}
                    />
                    <Card.Title
                      subtitleNumberOfLines={2}
                      style={[
                        styles.cardTitleStyle,
                        { alignItems: "flex-start" },
                      ]}
                      leftStyle={[styles.leftStyle, { marginTop: -2 }]}
                      title={
                        <CoreText
                          textTitle={t("app.dropOfItem_desc_4", "Visa din QR kod hos Postombudet så skrivs en fraktsedel ut")}
                          style={[styles.subtitleStyle]}
                          fontType={MontserratRegular}
                        />
                      }
                      titleNumberOfLines={2}
                      left={() => (
                        <Feather name="check" size={24} color="black" />
                      )}
                    />
                    <Card.Title
                      subtitleNumberOfLines={2}
                      style={[
                        styles.cardTitleStyle,
                        { alignItems: "flex-start" },
                      ]}
                      leftStyle={[styles.leftStyle, { marginTop: -2 }]}
                      title={
                        <CoreText
                          textTitle={t("app.dropOfItem_desc_5", "Se till att alla plagg som är godkända skickas med")}
                          style={[styles.subtitleStyle]}
                          fontType={MontserratRegular}
                        />
                      }
                      titleNumberOfLines={2}
                      left={() => (
                        <Feather name="check" size={24} color="black" />
                      )}
                    />
                  </Card>
                </View>
                {isLoad && (
                  <View style={{ paddingVertical: 20 }}>
                    <CoreLoader
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  </View>
                )}

                {!isLoad && bookingResponse && (
                  <View
                    style={
                      ([styles.DropcardContainer],
                      {
                        margin: 0,
                      })
                    }
                  >
                    <Card
                      style={
                        ([styles.DropMaincard],
                        {
                          backgroundColor: "unset",
                          borderRadius: 0,
                          boxShadow: "none",
                        })
                      }
                    >
                      <Card.Content
                        style={
                          ([styles.cardContent],
                          {
                            paddingHorizontal: 0,
                            paddingVertical: 0,
                          })
                        }
                      >
                        <Image
                          style={
                            ([styles.productImage],
                            {
                              margin: "auto",
                              width: 300,
                              height: 250,
                            })
                          }
                          source={{
                            uri: `data:image/gif;base64,${bookingResponse.data}`,
                          }}
                        />
                      </Card.Content>
                    </Card>
                  </View>
                )}
                <CoreText
                  textTitle={t("app.postCode_title", "Sök närmaste post beroende på Postnummer")}
                  style={{ fontSize: 14, color: Colors.BLACK }}
                  fontType={MontserratBold}
                />
                <View style={styles.searchDropIterm}>
                  <Searchbar
                    placeholder={t("app.postCode_placeholder", "Ange postnummer för hitta närmaste Posten ombud")}
                    style={styles.searchBar}
                    keyboardType="numeric"
                    placeholderTextColor={Colors.TEXT_GRAY}
                    inputStyle={styles.searchInput}
                    icon={() => (
                      <MaterialIcons
                        name="search"
                        size={20}
                        color={Colors.TEXT_GRAY}
                      />
                    )}
                    onChangeText={handleText}
                  />
                  {/* {message ? (
                <View>
                  <CoreText
                    textTitle={message}
                    style={styles.titleStyle}
                    fontType={MontserratBold}
                  />
                </View>
              ) : (
                <View />
              )} */}
                </View>

                <View
                  style={[
                    styles.produdtDetailsContainer,
                    { flexDirection: "column" },
                  ]}
                >
                  <View>
                    <CoreText
                      style={[styles.productDetails, { fontWeight: 700 }]}
                      textTitle={name}
                      fontType={MontserratBold}
                    />
                    <CoreText
                      style={styles.productAddress}
                      textTitle={
                        countryCode
                          ? `${streetName} ${streetNumber} ${city} ${countryCode} ${postalCode}`
                          : ""
                      }
                      fontType={MontserratRegular}
                    />
                    {/* <View>
                      {Platform.OS === "web" ? (
                        <WebMap service={service} />
                      ) : (
                        <MobileMap
                          coordinate={coordinate}
                          orgDetail={orgDetail}
                        />
                      )}
                      </View> */}
                  </View>
                </View>
                <View>
                  <Grid>
                    <Col>
                      <CoreText
                        style={[styles.productDetails, { marginBottom: 16 }]}
                        textTitle={t("app.dropOfItem_hours", "Öppetider:")}
                        fontType={MontserratBold}
                      />
                      <CoreText
                        style={[styles.weekDays, { marginBottom: 16 }]}
                        textTitle={t("app.monday_to_friday", "Måndag-Fredag")}
                        fontType={MontserratRegular}
                      />
                      <CoreText
                        style={[styles.weekDays, { marginBottom: 16 }]}
                        textTitle={t("app.saturday_sunday", "Lördag-Söndag")}
                        fontType={MontserratRegular}
                      />
                    </Col>
                    <Col>
                      <CoreText
                        style={styles.mainTiming}
                        textTitle={
                          postalServices &&
                          `${postalServices[0].openTime} - ${postalServices[0].closeTime}`
                        }
                        fontType={MontserratRegular}
                      />
                      <CoreText
                        style={styles.timing}
                        textTitle={
                          postalServices &&
                          `${postalServices[0].openTime} - ${postalServices[0].closeTime}`
                        }
                        fontType={MontserratRegular}
                      />
                      <CoreText
                        style={styles.timing}
                        textTitle={
                          postalServices &&
                          `${postalServices[5].openTime} - ${postalServices[5].closeTime}`
                        }
                        fontType={MontserratRegular}
                      />
                    </Col>
                  </Grid>
                </View>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
      <CoreFooter />
    </SafeAreaView>
  );
};
export default DropOffItem;
