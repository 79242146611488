import {
  SET_PRODUCT,
  PRODUCT_DETAILS,
  CREATE_USER,
  ALL_CATEGORY,
  SET_USER_POOL_DATA,
  SET_ORGANIZATION_SETTINGS
} from "../constants/actions-type";
const initialState = {
  products: {},
  productDetail: {},
  customerDetail: {},
  categories: {},
  userPool: {},
  orgSetting:{}
};

export const prodReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT:
      return { ...state, products: action.payload };
    case PRODUCT_DETAILS:
      return { ...state, productDetail: action.payload };
    case CREATE_USER:
      return { ...state, customerDetail: action.payload };
    case ALL_CATEGORY:
      return { ...state, categories: action.payload };
    case SET_USER_POOL_DATA:
      return { ...state, userPool: action.payload };
    case SET_ORGANIZATION_SETTINGS:
      return { ...state, orgSetting: action.payload };
    default:
      return state;
  }
};
